import moment from "moment";

export const getTimepicker = (work_hours, timezone) => { 
    console.log(work_hours);
    ///close 18:00 
    //karna timezone
    
    const openTime = moment(work_hours.open, "HH:mm");
    const closeTime = moment(work_hours.close, "HH:mm");
    const initDays = moment().utcOffset(+timezone);
    let currentTime = moment().utcOffset(+timezone);
    const interval = 30; // Interval in minutes

    // Ensure current time is within the working hours
    if (currentTime.isBefore(openTime)) {
        currentTime = openTime.clone();
    }

    // Add 30 minutes to the current time for the first slot
    currentTime.add(interval, 'minutes');

    const time = [];

    // Add the first time slot
    if (currentTime.isBefore(closeTime)) {
        time.push(currentTime.format('HH:mm'));
    }

    // Round to the next full 30-minute interval
    currentTime.minutes(Math.ceil(currentTime.minutes() / interval) * interval);
    currentTime.seconds(0);
    
    // Generate subsequent time slots
    while (currentTime.isBefore(closeTime)) {
        const nextTimeSlot = currentTime.format('HH:mm');
        // Ensure no duplicates
        if (!time.includes(nextTimeSlot) && closeTime.format('HH:mm') > nextTimeSlot && initDays.format('ddd') === currentTime.format('ddd')) {
            time.push(nextTimeSlot);
        }

        currentTime.add(interval, 'minutes');
    }

    // Ensure the last time slot is the closing time
    const closingTimeFormatted = closeTime.format('HH:mm');
    if (!time.includes(closingTimeFormatted) && currentTime.isSameOrAfter(closeTime)) {
        time.push(closingTimeFormatted);
    }

    // removing case when if current time + interval is less than 10 minutes from the next interval
    // remove the next interval
    // if (time.length > 1) {
    //     const firstTime = moment(time[0], 'HH:mm');
    //     const secondTime = moment(time[1], 'HH:mm');

    //     if (secondTime.diff(firstTime, 'minutes') <= 10) {
    //         // Remove the second time slot
    //         time.splice(1, 1);
    //     }
    // }

    // console.log(time)


    return time;
};
